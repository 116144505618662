import styles from "./quotes.module.scss"
import React, { Component } from "react"

//-- Quotes container with tab links
export class Quotes extends Component {
  render() {
    return (
      <div className="row">
        <div className="col">
          <div className={styles.quotes__wrapper}>
            <div className={styles.quotes__header}>
              <div className={styles.quotes__headerImage}>
                <img
                  src={this.props.imgurl}
                  alt={this.imgalt}
                  style={{ width: this.props.width, height: this.props.height }}
                />
              </div>
            </div>

            <div className={styles.quotes__body}>{this.props.children}</div>

            <div className={styles.quotes__author}>
              <p className={styles.quotes__authorName}>{this.props.author}</p>
              <p className={styles.quotes__authorPosition}>
                {this.props.authortitle}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
