import styles from "../../styles/voltstack.module.scss"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import checksStyles from "../../styles/commons/circlechecks.module.scss"
import React, { useState } from "react"
import Layout from "../../../src/components/layout/layout"
import StartDemo from "../../../src/components/start-demo/start-demo"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  VS_BLOCK_AIS,
  VS_BLOCK_CDV,
  VS_BLOCK_CLUSTERING,
  VS_BLOCK_CS,
  VS_BLOCK_DAM,
  VS_BLOCK_DIM,
  VS_BLOCK_DS,
  VS_BLOCK_MK,
  VS_BLOCK_MULTI_TENANCY,
  VS_BLOCK_OBSERVABILITY,
  VS_BLOCK_OPTIMIZED_OS,
} from "../../utils/constants"
import ArrowSvg from "../../components/svg/arrow"
import PlaySvg from "../../components/svg/play"
import SEO from "../../components/seo"
import withAppContext from "../../contexts/app.context"
import { ENV_CONSOLE_BASE_URL, ENV_DOCS_BASE_URL } from "../../utils/secrets"
import { ProductsUseCases } from "../../components/products-use-cases/products-use-cases"
import { Quotes } from "../../components/quotes/quotes"
import FsLightbox from "fslightbox-react"
import { Helper } from "../../utils/helper"
import AlternatingFeatures from "../../components/alternating-features/alternating-features"

const VoltStackPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "products__voltstack" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const [selectedBlock, setSelectedBlock] = useState(null)
  const [openedSchemaIndex, setOpenedSchemaIndex] = useState(1)

  const setSelectedBlockWrapper = (event, value) => {
    event.stopPropagation()
    setSelectedBlock(value)
  }

  const toggleOpenedSchemaIndex = (event, value) => {
    event.stopPropagation()
    if (value !== openedSchemaIndex) {
      setOpenedSchemaIndex(value)
    } else {
      setOpenedSchemaIndex(0)
    }
  }

  const overviewVideo = "https://player.vimeo.com/video/444944008?autoplay=1"
  const gatewayVideoLink = "https://player.vimeo.com/video/452281491?autoplay=1"
  const induServerVideoLink =
    "https://player.vimeo.com/video/452284343?autoplay=1"
  const hardwareVideoLink =
    "https://player.vimeo.com/video/448459308?autoplay=1"

  const [videoLink, setVideoLink] = useState(overviewVideo)
  const [isVideoOpened, setVideoOpened] = useState(false)

  const playVideo = link => () => {
    setVideoLink(link)
    Helper.triggerGtagVideo(videoLink)
    setVideoOpened(!isVideoOpened)
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/products/voltstack"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero block */}
      <section className={styles.header}>
        <div className="container volterra-container d-flex flex-column flex-lg-row">
          <div className={`row ${styles.header__wrapper}`}>
            <h1
              className={`${styles.header__title} ${styles.header__titleHighlighted}`}>
              VoltStack<span>&trade;</span>
            </h1>
            <h2 className={styles.header__title}>
              Deploy & Operate Globally Distributed Apps
            </h2>
            <p className={`mb-0 ${styles.header__description}`}>
              SaaS-based offering that automates deployment, security and
              operations of distributed apps and infrastructure across the edge,
              public/private clouds and our global application delivery network
            </p>
            <div className={styles.header__cta}>
              <a
                aria-label="Start for Free"
                href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                className={"btn btn-primary " + styles.header__btn}>
                Start for Free
              </a>
              <button
                onClick={playVideo(overviewVideo)}
                className={`btn btn-primary ml-4 ${styles.header__btn} ${styles.header__btnPlay}`}>
                <PlaySvg />
                Watch overview
              </button>
            </div>
          </div>
          <div className={styles.header__imageWrapper}>
            <div
              aria-label="VoltStack image"
              className={styles.header__image}
              onClick={playVideo(overviewVideo)}>
              <PlaySvg />
            </div>
          </div>
        </div>
      </section>

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            src={videoLink}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />

      {/* Challenges & Solutions */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__tl}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Cloud-native software stack for <br /> managing distributed
              workloads
            </h2>
          </div>
          <div className="row list justify-content-center justify-content-xl-between">
            <div className={styles.list__item}>
              <h3 className={styles.list__header}>Challenges</h3>
              <img
                src="/img/products/vs-challenges.png"
                alt="Challenges"
                className={styles.services__diagram}
              />
              <ul
                className={`${styles.list__body} ${styles.list__bodyChallenge}`}>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemChallenge}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Cost and Complexity
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Heterogeneous infrastructure with different tooling and
                      operations across cloud and edge raises complexity and
                      cost
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemChallenge}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Scalability Challenges
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Manual workflows and application orchestration across
                      large number of distributed sites makes it difficult to
                      scale
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemChallenge}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Slow Time-to-Service{" "}
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Deployment and lifecycle management of apps on independent
                      infrastructure nodes impedes service velocity
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemChallenge}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>Higher Risk</h4>
                    <p className={styles.list__bodyItemText}>
                      Disjointed identity, lack of end-to-end security, and
                      incomplete visibility creates blind spots and increases
                      risk
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className={styles.list__item}>
              <h3 className={styles.list__header}>Solutions</h3>
              <img
                src="/img/products/vs-solutions.png"
                alt="Solutions"
                className={styles.services__diagram}
              />
              <ul
                className={`${styles.list__body} ${styles.list__bodySolution}`}>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemSolution}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Cost Optimized and Lower Complexity
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Consistent platform with SaaS based operations across
                      heterogeneous infrastructure reduces complexity and cost
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemSolution}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Seamless Scalability
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Globally distributed control plane with resource
                      orchestration across a fleet of distributed clusters
                      enables massive scale
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemSolution}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Rapid Service Delivery
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      SaaS-based deployment and lifecycle management across the
                      fleet with centralized intent and policy increases agility
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemSolution}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>Reduced Risk</h4>
                    <p className={styles.list__bodyItemText}>
                      Uniform identity, zero trust security, centralized
                      observability, continuous verification removes blind spots
                      and reduces risk
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Platform Overview */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__tr}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              SaaS-based platform to deploy, secure and operate apps in the
              edge, cloud and our app delivery network
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.textblock}>
                <p className={cblockStyles.cblock__intro}>
                  A modern platform that consolidates multiple services across
                  many app clusters
                </p>
                <ul
                  className={"list-unstyled my-4 " + checksStyles.circlechecks}>
                  <li className={checksStyles.circlechecks__item}>
                    Zero-touch provisioning of infrastructure nodes, platform
                    services and apps at the edge or cloud
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    Distributed control plane with Kubernetes APIs to manage a
                    large fleet of app clusters
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    Universal identity, secrets, KMS and service mesh for
                    security of apps and data across multiple clusters
                  </li>
                </ul>
                <p>
                  Self-service and collaboration across developers, DevOps and
                  NetOps
                </p>
                <p>
                  High-performance{" "}
                  <Link to="/products/volterra-global-network">
                    global network
                  </Link>{" "}
                  with the ability to run apps and connect the distributed
                  clusters
                </p>
                <p>
                  24x7 SRE and SOC service reduces the burden of operating a
                  distributed system
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <picture>
                <source
                  srcSet="/img/products/voltstack-graph-global-network.png"
                  media="(min-width: 576px)"
                />
                <img
                  className="img-fluid"
                  src="/img/products/voltstack-graph-global-network-sm.png"
                  alt="A graph showing Volterra Global Network app delivery"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>

      {/* Quotes */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <Quotes
            imgurl="/img/products/net-one.png"
            width="140px"
            height="85px"
            imgalt="Net One logo"
            author="Fumihiko Shinoura"
            authortitle="SVP/GM Business Development, Net One Systems">
            <p>
              “VoltStack has dramatically simplified our ability to manage and
              secure distributed infrastructure, applications and data across
              edge and cloud. Our teams now focus on applications, while
              Volterra handles the operations of the platform.”
            </p>
          </Quotes>
        </div>
      </section>

      {/* Key Capabilities */}
      <section className={`${cblockStyles.cblock} ${styles.capabilities}`}>
        <div className="container">
          <div
            className={`${cblockStyles.cblock__header} ${cblockStyles.cblock__headerShort}`}>
            <h2 className={cblockStyles.cblock__head}>Key Capabilities</h2>
            <p>
              VoltStack delivers platform services to deploy, secure and operate
              infra + apps across the edge, multiple clouds and our global
              network
            </p>
          </div>
        </div>
        <div className={`${styles.schema} row`} ves-e2e-test="voltstack/schema">
          <div className={styles.schema__wrapper}>
            <div className={styles.schema__bodyWrapper}>
              <div className={styles.schema__bodyContainer}>
                <div
                  className={`${styles.schema__body} ${
                    openedSchemaIndex === 1 ? styles.schema__bodyOpened : ""
                  }`}>
                  <p
                    className={styles.schema__title}
                    onClick={event => toggleOpenedSchemaIndex(event, 1)}>
                    Application Services
                    <i
                      className={`${styles.schema__titleIcon}  ${
                        openedSchemaIndex === 1
                          ? styles.schema__titleIconOpened
                          : ""
                      }`}
                    />
                  </p>
                  <div className={styles.schema__bodyRow}>
                    <div
                      aria-label="Distributed Application Management Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_DAM)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockTriple} ${styles.schema__blockEnabled}`}>
                      <span>Distributed Application Management</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_DAM
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_DAM
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Distributed Application <br /> Management
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Deploy and orchestrate applications across a segment
                          or the entire fleet of clusters. Centralized
                          SaaS-based management of distributed applications with
                          a single pane of glass and rich observability.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Fleet-wide digital twin to define intent and
                              policies
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Aggregated application health and visibility
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Fleet segmentation for management, reliability,
                              compliance and beta testing
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Security, identity and key management</div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/app-stack/distributed-application-management`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div className={styles.schema__block}>
                      <span>API Gateway</span>
                    </div>
                    <div className={styles.schema__block}>
                      <span>App Security</span>
                    </div>
                  </div>
                  <div className={styles.schema__bodyRow}>
                    <div
                      aria-label="Continuous Delivery & Verification Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_CDV)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockMd} ${styles.schema__blockEnabled}`}>
                      <span>Continuous Delivery & Verification</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_CDV
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_CDV
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Continuous Delivery & Verification
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Continuously verify application and infrastructure
                          health to generate alerts and automatically take
                          actions upon detecting anomalies. Integrate with
                          customers’ existing CI/CD pipelines to ensure minimal
                          operational disruption.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              AI/ML-based analysis of application logs for
                              faster troubleshooting
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Continuous analysis of infrastructure metrics and
                              logs for faster recovery
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Proactive monitoring with alerts and automation
                              rules
                            </div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/app-stack/continuous-delivery-&-verification`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      aria-label="App Identity and Secrets Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_AIS)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockMd} ${styles.schema__blockEnabled}`}>
                      <span>
                        App Identity <br /> and Secrets
                      </span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_AIS
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_AIS
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            App Identity and Secrets
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Uniform identity for applications across different
                          multi-cloud and/or edge environments. Manage identity
                          lifecycle for each application instance via automatic
                          certificate rotation, increasing security and
                          operational efficiency.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Cryptographically secure double-blinding to
                              securely store passwords, keys and TLS
                              certificates
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Integrate with existing secrets management
                              solutions
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Identity-based secret access</div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/app-stack/application-identity`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      aria-label="Container Security Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_CS)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockMd} ${styles.schema__blockEnabled}`}>
                      <span>
                        Container <br /> Security
                      </span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_CS
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_CS
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Container Security
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Container-level security services to protect and
                          isolate applications and infrastructure from errant or
                          malicious containers.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              VM-like isolation boundaries with container-like
                              resource footprint
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              QoS assurance with granular resource limits per
                              container
                            </div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/about-f5-distributed-cloud/app-stack#key-app-stack-services`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockMd}`}>
                      <span>Load Balancing</span>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockMd}`}>
                      <span>Service Mesh</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.schema__body} ${
                    openedSchemaIndex === 2 ? styles.schema__bodyOpened : ""
                  }`}>
                  <p
                    className={`${styles.schema__title} ${styles.schema__titleIndent}`}
                    onClick={event => toggleOpenedSchemaIndex(event, 2)}>
                    Infrastructure Services
                    <i
                      className={`${styles.schema__titleIcon}  ${
                        openedSchemaIndex === 2
                          ? styles.schema__titleIconOpened
                          : ""
                      }`}
                    />
                  </p>
                  <div className={styles.schema__bodyRow}>
                    <div
                      aria-label="Distributed Infrastructure Management Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_DIM)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockTriple} ${styles.schema__blockEnabled}`}>
                      <span>Distributed Infrastructure Management</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_DIM
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_DIM
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Distributed Infrastructure Management
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Manage globally distributed and/or heterogeneous
                          infrastructure as a fleet. SaaS-based management with
                          a single pane of glass and rich observability.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Management and upgrades of components such as
                              operating system, host networking and storage
                              across a segment or entire fleet
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Fleet-wide digital twin to define intent and
                              policies across all sites
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Near real-time observability across fleet</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Certificate and identity management</div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/app-stack/distributed-infrastructure-management`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockDouble}`}>
                      <span>Secure Networking</span>
                    </div>
                  </div>
                  <div className={styles.schema__bodyRow}>
                    <div
                      aria-label="Optimized OS Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_OPTIMIZED_OS)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockSmall} ${styles.schema__blockEnabled}`}>
                      <span>Optimized OS</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_OPTIMIZED_OS
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_OPTIMIZED_OS
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Optimized Operating System
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          A consistent and efficient operating system for
                          deployment across heterogeneous cloud and edge
                          locations, optimized to run virtual machines,
                          containers, and Lambda workloads
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Low memory footprint</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Support for multiple processor architectures (e.g.
                              x86)
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Support for remote and zero-touch upgrades and
                              rollbacks
                            </div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/app-stack/optimized-operating-system`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      aria-label="Clustering Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_CLUSTERING)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockSmall} ${styles.schema__blockEnabled}`}>
                      <span>Clustering</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_CLUSTERING
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_CLUSTERING
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Clustering
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Scale compute and storage resources seamlessly by
                          clustering multiple nodes together to match service
                          needs.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Zero-touch scale-out of individual nodes
                              (active-active)
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Immediate app deployability after a node is added
                              to a cluster
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Storage replication across nodes within a cluster
                              for fast app restart upon hardware or software
                              failure
                            </div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/app-stack/clustering`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      aria-label="Distributed Storage Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_DS)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockSmall} ${styles.schema__blockEnabled}`}>
                      <span>Distributed Storage</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_DS
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_DS
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Distributed Storage
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Software-defined storage across nodes/clusters.
                          Consistent volumes across heterogeneous storage types.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>High availability storage for fail-overs</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Scheduled backups to cloud object stores</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Disaster recovery across distributed sites
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Encryption for storage</div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/about-f5-distributed-cloud/app-stack#key-app-stack-services`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      aria-label="Managed Kubernetes Details"
                      role="button"
                      onClick={event =>
                        setSelectedBlockWrapper(event, VS_BLOCK_MK)
                      }
                      className={`${styles.schema__block} ${styles.schema__blockSmall} ${styles.schema__blockEnabled}`}>
                      <span>Managed Kubernetes</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VS_BLOCK_MK
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VS_BLOCK_MK
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Managed Kubernetes
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Managed Kubernetes environment to orchestrate and
                          scale infrastructure resources in an automated
                          fashion.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Automatic, storage-aware placement of workloads
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Mixing of critical and best-effort workloads
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Self-healing resources</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Progressive roll-out with health monitoring for
                              automated rollback
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Ability to scale manually or programmatically
                            </div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/app-stack/managed-kubernetes`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockDouble} ${styles.schema__blockDoubleMd}`}>
                      <span>Secure Backbone</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.schema__body} ${styles.schema__bodyProgrammability}`}>
                <div
                  className={`${styles.schema__block} ${styles.schema__blockVertical}`}>
                  <span>Programmability</span>
                </div>
              </div>
            </div>
            <div
              className={`${styles.schema__body} ${
                styles.schema__bodyInfrastructure
              } ${openedSchemaIndex === 3 ? styles.schema__bodyOpened : ""}`}>
              <p
                className={styles.schema__title}
                onClick={event => toggleOpenedSchemaIndex(event, 3)}>
                Infrastructure
                <i
                  className={`${styles.schema__titleIcon}  ${
                    openedSchemaIndex === 3
                      ? styles.schema__titleIconOpened
                      : ""
                  }`}
                />
              </p>
              <div className={styles.schema__bodyRow}>
                <div
                  className={`${styles.schema__block} ${styles.schema__blockInfrastructure}`}>
                  <span>
                    Edge <br /> (Volterra or Commodity)
                  </span>
                </div>
                <div
                  className={`${styles.schema__block} ${styles.schema__blockInfrastructure}`}>
                  <span>
                    Network <br /> (Volterra or Telco)
                  </span>
                </div>
                <div
                  className={`${styles.schema__block} ${styles.schema__blockInfrastructure}`}>
                  <span>
                    Cloud <br /> (Public/Private)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.schema__body} ${styles.schema__bodyVertical}`}>
            <div
              aria-label="Observability Details"
              role="button"
              onClick={event =>
                setSelectedBlockWrapper(event, VS_BLOCK_OBSERVABILITY)
              }
              className={`${styles.schema__block} ${styles.schema__blockEnabled}`}>
              <span>Observability</span>
              <div
                className={
                  styles.schema__blockPopup +
                  " " +
                  (selectedBlock === VS_BLOCK_OBSERVABILITY
                    ? styles.schema__blockPopupAppear
                    : "") +
                  " " +
                  (selectedBlock !== VS_BLOCK_OBSERVABILITY
                    ? styles.schema__blockPopupDisappear
                    : "")
                }>
                <div className="d-flex flex-row justify-content-between">
                  <h5 className={styles.schema__blockPopupTitle}>
                    Observability
                  </h5>
                  <img
                    src="/img/common/cross.svg"
                    alt=""
                    className={styles.schema__blockPopupCross}
                    onClick={event => setSelectedBlockWrapper(event, null)}
                  />
                </div>
                <p className={styles.schema__blockPopupDescription}>
                  Insights across heterogeneous cloud environments, networks and
                  application layers to provide a full view of application and
                  infrastructure performance, security and health.
                </p>
                <ul className={styles.schema__blockPopupList}>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>
                      Global visibility of network and application performance
                    </div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Logs and metrics, alerting and auditability</div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Service-level connectivity metrics and tracing</div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Custom dashboards</div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Integration APIs for 3rd parties</div>
                  </li>
                </ul>
                <a
                  href={`${ENV_DOCS_BASE_URL}/services/app-stack/observability`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.schema__blockPopupLink}>
                  Learn more
                  <ArrowSvg />
                </a>
              </div>
            </div>
            <div
              aria-label="Multi-Tenancy Details"
              role="button"
              onClick={event =>
                setSelectedBlockWrapper(event, VS_BLOCK_MULTI_TENANCY)
              }
              className={`${styles.schema__block} ${styles.schema__blockEnabled}`}>
              <span>Multi-Tenancy</span>
              <div
                className={
                  styles.schema__blockPopup +
                  " " +
                  (selectedBlock === VS_BLOCK_MULTI_TENANCY
                    ? styles.schema__blockPopupAppear
                    : "") +
                  " " +
                  (selectedBlock !== VS_BLOCK_MULTI_TENANCY
                    ? styles.schema__blockPopupDisappear
                    : "")
                }>
                <div className="d-flex flex-row justify-content-between">
                  <h5 className={styles.schema__blockPopupTitle}>
                    Multi-Tenancy
                  </h5>
                  <img
                    src="/img/common/cross.svg"
                    alt=""
                    className={styles.schema__blockPopupCross}
                    onClick={event => setSelectedBlockWrapper(event, null)}
                  />
                </div>
                <p className={styles.schema__blockPopupDescription}>
                  Run third-party and/or multiple business lines’ applications
                  while providing complete isolation of compute, network and
                  storage resources. Provides the ability to run heterogeneous
                  workloads (containers, VMs) across different namespaces within
                  a tenant.
                </p>
                <ul className={styles.schema__blockPopupList}>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>
                      Virtual private compute, storage and networking per
                      namespace
                    </div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Multiple VPNs per namespace</div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>
                      Multi-tenancy across shared application and security
                      services
                    </div>
                  </li>
                </ul>
                <a
                  href={`${ENV_DOCS_BASE_URL}/about-f5-distributed-cloud/mesh#key-mesh-services`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.schema__blockPopupLink}>
                  Learn more
                  <ArrowSvg />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why VoltStack */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__invert} ${cblockStyles.cblock__invertUsecases}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Why VoltStack?</h2>
          </div>
          <div className="row justify-content-between">
            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/operations-icon-alt.svg"
                alt=""
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>
                  Operational Simplification
                </h3>
                <p className={styles.useCases__text}>
                  VoltStack delivers up to a 12x reduction in time-to-market by
                  providing an integrated and consistent set of cloud services
                  across any environment. Our SaaS-based platform and global
                  network allows you to focus on your apps, simplifying your
                  operations.
                </p>
              </div>
            </div>

            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/shield-icon.svg"
                alt=""
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>System-wide Security</h3>
                <p className={styles.useCases__text}>
                  Our innovative Blindfold&trade; and identity management system
                  ensure the security of your secrets and keys. VoltStack’s
                  multi-cluster service mesh removes the need for you to
                  integrate the multiple networking services required to meet
                  security and compliance requirements.
                </p>
              </div>
            </div>

            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/cost-icon.svg"
                alt=""
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>TCO Reduction</h3>
                <p className={styles.useCases__text}>
                  Our multi-tenant platform delivers an up to 70% reduction in
                  CapEx + OpEx by removing the need to integrate multiple cloud
                  or software services. Our global network gives you the
                  flexibility of deploying in new geographies without the need
                  to build out new capacity and infrastructure.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Infrastructure Options */}
      <section
        className={`pb-0 ${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__bl}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Infrastructure Options for VoltStack
            </h2>
            <p>
              VoltStack services runs on Volterra nodes that can be deployed on
              Volterra hardware, certified commodity hardware, and public or
              private cloud
            </p>
          </div>
        </div>
        <div className={`container ${styles.optionsContainer}`}>
          <div className={`row ${styles.options__list}`}>
            <div className={styles.options__item}>
              <img
                className={styles.options__img}
                src="/img/products/tabletop.png"
                alt="Volterra Industrial Gateway"
              />
              <h3 className={styles.options__title}>
                Volterra Industrial Gateway
              </h3>
              <p className={styles.options__description}>
                Customized appliance for edge use cases <br />
                such as manufacturing line/robot edge <br />
                and retail stores.
              </p>
              <div className="d-flex">
                <a
                  href={`/resources/doc/Volterra_Industrial_Gateway_Datasheet.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.options__link}>
                  Learn more
                  <ArrowSvg />
                </a>
                <button
                  ves-e2e-test="voltstack/infra-btn-video"
                  className={`ml-4 ${styles.options__link} ${styles.options__linkVideo}`}
                  onClick={playVideo(gatewayVideoLink)}>
                  Watch video
                  <PlaySvg />
                </button>
              </div>
            </div>
            <div className={styles.options__item}>
              <img
                className={styles.options__img}
                src="/img/products/rack.png"
                alt="Volterra Industrial Server"
              />
              <h3 className={styles.options__title}>
                Volterra Industrial Server
              </h3>
              <p className={styles.options__description}>
                Customized server for private cloud, <br />
                network/telco edge and high-capacity <br />
                enterprise edge sites.
              </p>
              <div className="d-flex">
                <a
                  href={`/resources/doc/Volterra_Industrial_Server_Datasheet_R2.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.options__link}>
                  Learn more
                  <ArrowSvg />
                </a>
                <button
                  ves-e2e-test="voltstack/infra-btn-video"
                  className={`ml-4 ${styles.options__link} ${styles.options__linkVideo}`}
                  onClick={playVideo(induServerVideoLink)}>
                  Watch video
                  <PlaySvg />
                </button>
              </div>
            </div>
            <div className={styles.options__item}>
              <img
                className={styles.options__img}
                src="/img/products/commodity.png"
                alt="Commodity Hardware, Public Clouds"
              />
              <h3 className={styles.options__title}>
                Commodity Hardware, Public Clouds
              </h3>
              <p className={styles.options__description}>
                Range of certified 3rd party hardware, from <br />
                low-end gateways to high-end servers, as <br />
                well as public clouds.
              </p>
              <div className="d-flex">
                <a
                  href={`/docs/how-to/site-management`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.options__link}>
                  Learn more
                  <ArrowSvg />
                </a>
                <button
                  ves-e2e-test="voltstack/infra-btn-video"
                  className={`ml-4 ${styles.options__link} ${styles.options__linkVideo}`}
                  onClick={playVideo(hardwareVideoLink)}>
                  Watch video
                  <PlaySvg />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Ecosystem and Integrations */}
      <div
        className={`${cblockStyles.cblock} ${styles.ecosystem} ${cblockStyles.cblock__bubbles__br}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Ecosystem & Integrations
            </h2>
          </div>
          <AlternatingFeatures
            props={{
              features: [
                {
                  title: "Diverse Cloud Provider & Physical HW Support",
                  copy:
                    "Use VoltStack to deploy, secure and operate apps in the cloud or on edge site hardware from vendors including Dell, HPE and more. VoltStack also provides lifecycle management of the infrastructure nodes.",
                  imgUrl: "/img/products/vs-ecosystem1.svg",
                  imgAlt:
                    "Diagram showing Google Cloud, AWS, Azure, Wmware, Dell and HP Enterprise logotypes",
                },
                {
                  title: "Broad Support for Operations & Security Tools",
                  copy:
                    "Support for leading operations management tools such as Opsgenie or PagerDuty for alerting, Splunk or Datadog for logging, and Splunk or IBM for SIEM.",
                  imgUrl: "/img/products/vs-ecosystem2.svg",
                  imgAlt:
                    "Diagram showing Datadog, Slack, Opsgenie, PagerDuty, Splunk and ServiceNow logotypes",
                },
                {
                  title: "Native Support for Developer Tools",
                  copy:
                    "Deliver to the automation needs of app teams with Volterra Terraform provider, vesctl and kubectl for CLI, as well as public APIs. Use your existing CI/CD tools like CircleCI, Spinnaker and GitLab.",
                  imgUrl: "/img/products/vs-ecosystem3.svg",
                  imgAlt:
                    "Diagram showing Terraform, GitLab, Spinnaker, Kubernetes and CircleCI logotypes",
                },
              ],
            }}
          />
        </div>
      </div>

      {/* Use Cases */}
      <div
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__grey} ${cblockStyles.cblock__bubbles__vmtlmr}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>VoltStack Use Cases</h2>
          </div>
          <ProductsUseCases>
            <div
              title="Edge App Management"
              link={"/solutions/edge/edge-infrastructure-and-app-management"}
              imgurl="/img/products/networking-icon.svg"
              imgalt="Stack of servers icon">
              <p>
                Deliver a unified “edge cloud” using a rich set of platform
                services to provision and operate distributed infrastructure and
                apps. VoltConsole simplifies operations and the Volterra network
                secures connectivity across edge and cloud.
              </p>
            </div>
            <div
              title="App Delivery Network (ADN)"
              link={"/solutions/network/app-delivery-network"}
              imgurl="/img/products/network-edge-icon.svg"
              imgalt="Cloud with links icon">
              <p>
                Offload apps from public or private clouds to our global
                application delivery network. Run parts of app logic closer to
                users to reduce network costs and improve latency / throughput
                without requiring changes to your existing CI/CD tools.
              </p>
            </div>
          </ProductsUseCases>
        </div>
      </div>

      <StartDemo />
    </Layout>
  )
}

export default withAppContext(VoltStackPage)
