import styles from "./alternating-features.module.scss"
import React from "react"

const AlternatingFeatures = ({ props }) => {
  const { features } = props

  return (
    <div className={styles.altfeats}>
      {features.map(feature => (
        <div
          key={feature.title}
          className={"row align-items-center " + styles.altfeats__row}>
          <div className={"col-md-6 " + styles.altfeats__imgcol}>
            <img
              className={styles.altfeats__img}
              src={feature.imgUrl}
              alt={feature.imgAlt}></img>
          </div>
          <div className="col-md-6">
            <div className={styles.altfeats__inner}>
              <h5>{feature.title}</h5>
              <p>{feature.copy}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AlternatingFeatures
